<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }" v-show="popTips.visible">
    <div class="box">
      <div class="pop_title">
        <div class="title_left">
          <img src="../../../assets/images/channel/定位.png" />
          <div>
            船舶名称：<span>{{ popData.name }}</span>
          </div>
        </div>
        <div class="close" @click="popClose"><i class="el-icon-close"></i></div>
      </div>

      <div class="content">
        <div class="pop_icon">
          <div class="outer">
            <div class="inside"></div>
            <div class="point">
              <img src="../../../assets/images/channel/船舶.png" />
            </div>
          </div>
          <!-- <div class="pop_btn" v-if="routeBtnSign">
            <div :class="{ active: isShowForecast }" @click="showForecastRoute(popData.id)">预测路线</div>
            <div :class="{ active: isShowHistory }" @click="showHistoryRoute(popData.id)">历史路线</div>
          </div> -->
        </div>
        <div class="pop_right">
          <div class="pop_detail">
            <div class="item">流速：<span>xxx</span></div>
            <div class="item">流向：<span>xxx</span></div>
            <div class="item">潮位：<span>xxx</span></div>
            <div class="item">富余水深<span>xxx</span></div>
          </div>
          <div class="pop_more"><i class="el-icon-caret-bottom"></i><span>查看更多</span></div>
        </div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'ForecastPop',
  data() {
    return {
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      },
      popData: {},
      longitude: '',
      latitude: ''
    }
  },
  mounted() {
    mapApp.forecastPopVueComponent = this
    console.log('加载完成')
  },
  destroyed() {
    mapApp.forecastPopVueComponent = null
  },
  // 组件方法
  methods: {
    popClose() {
      mapApp.closeForecastPop()
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;
  pointer-events: none;
  // animation: shutter-in-bottom 0.5s;
  .box {
    position: relative;
    top: -10px;
    // transform: translateX(50%);
    // width: 200px;
    padding: 10px 15px;
    box-sizing: border-box;
    border: 1px solid #1c899c;
    background-color: #03050eb0;
    color: #fff;
    font-size: 12px;
    // font-weight: bolder;
    white-space: nowrap;
    font-family: SiYuan;
    // animation: shutter-in-bottom 0.5s ease forwards;
    .content {
      display: flex;
      justify-content: space-between;
      .pop_icon {
        padding-left: 15px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 130px;
        .outer {
          position: relative;
          width: 72px;
          height: 72px;
          background-image: url('../../../assets/images/channel/circle.png');
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .point {
            width: 62px;
            height: 62px;
            background-image: url('../../../assets/images/channel/circle_point.png');
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 36px;
              height: 38px;
              margin-bottom: 4px;
            }
          }
          .inside {
            position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            width: 72px;
            height: 72px;
            background-image: url('../../../assets/images/channel/circle1.png');
            background-size: 100%;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: circle_rotate 5s infinite;
            animation-timing-function: linear;
          }
        }
        .pop_btn {
          margin-top: 5px;
          div {
            height: 20px;
            width: 50px;
            line-height: 20px;
            padding: 0 5px;
            background-color: #275185;
            margin-top: 5px;
            text-align: center;
            pointer-events: auto;
            cursor: pointer;
          }
          .active {
            background-color: #0073ff;
          }
        }
      }
      .pop_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .pop_detail {
        margin: 10px 0 10px 10px;
        display: grid;
        grid-template-columns: 50% 50%;
        // grid-template-rows: 100px 100px 100px;
        .item {
          margin: 0 10px 20px 5px;
          span {
            font-size: 14px;
            color: #04d9ff;
          }
        }
        .pop_dynamic {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }

      .pop_more {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        border-bottom: 20px solid #275185;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        height: 0;
        width: 70px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        i {
          padding-right: 2px;
        }
        span {
          font-size: 12px;
        }
      }
    }

    .pop_title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-image: linear-gradient(to right, #173d66, rgba(0, 0, 0, 0));
      padding: 5px;
      line-height: 24px;
      vertical-align: middle;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      .title_left {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        div {
          margin-left: 5px;
        }
      }
      .close {
        width: 18px;
        height: 18px;
        color: #27abc2;
        margin-right: 10px;
        margin-top: 2px;
        box-sizing: border-box;
        border: 2px solid #1e92a7;
        text-align: center;
        line-height: 12px;
        cursor: pointer;
        pointer-events: auto;
        i {
          font-size: 12px;
          font-weight: bolder;
        }
      }
    }
  }
  .line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    width: 2px;
    height: 40px;
    background-color: #25bcd6;
  }
}
@keyframes shutter-in-bottom {
  0% {
    transform: rotateY(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
</style>
