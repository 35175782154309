<template>
  <video :id="playerID" class="video-js">
    <source :src="url" />
  </video>
</template>
<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props:{
    playerID: {
      type: String,
      default: "my-video"
    },
    url:{
      type: String,
      default: "http://39.105.44.210:9000/live1/index.m3u8"
    },
    isPlay: {
      type: Boolean,
      default: false
    },
    options:{
      type: Object,
      default: function(){
        return {}
      }
    }
  },
  data(){
    return {
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true, // 控制按钮
        bigPlayButton: true,
        preload:"metadata", // 预先加载
        textTrackDisplay: true, // 不显示追踪面板
        errorDisplay: false,
        controlBar: {
          playToggle: true,  // 不显示播放暂停
          pictureInPictureToggle: false, // 不显示画中画
          volumePanel: false, // 不显示音量
          liveDisplay: true, // 显示live字样
          fullscreenToggle: true, // 显示全屏按钮
        },
        width: "158px",
        height: "105px",
      }
    }
  },
  mounted(){
    Object.assign(this.videoOptions,this.options)
    this.player = videojs(
      this.playerID,
      this.videoOptions,
      ()=>{}
    );
    // 获取bigbutton
    // const bigButton = this.player.bigPlayButton
  },
  beforeDestroy(){
    if(this.player){
      this.player.dispose()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
